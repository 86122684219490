import React from "react";
import styled from "styled-components";
// import { Button } from "../../components/Button/Button";
import { Text } from "../../components/Text/Text";
import banner from "../../images/banner-venture.png";
import { color, typography } from "../../shared/style";

export const Banner = ({ banner, title, description, group_button }) => {
  return (
    <WrapperSection banner={banner?.url}>
      <Content>
        <TextExtend
          font={typography.type.code}
          tag="h2"
          color={color.primary.white}
          capital="uppercase"
          weight={typography.weight.regular1}
          align="center"
          size={120}
          lineHeight={120}
        >
          {title}
        </TextExtend>
        <Description
          font={typography.type.primary}
          color={color.primary.white}
          weight={typography.weight.bold}
          align="center"
          size={typography.size.s3}
          lineHeight={26}
        >
          {description}
        </Description>
        {/* <GroupButton>
          {group_button && group_button.map((item, i) => (
            <ButtonExtended
              key={i}
              onClick={() => window.location.href = "#"}
              type={i == 0 ? "primary" : "secondary"}>{item?.button_label?.text}</ButtonExtended>
          ))}
        </GroupButton> */}
      </Content>
    </WrapperSection>
  );
};

// const GroupButton = styled.div`
//   display: flex;
//   justify-content: center;
//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
//   @media (max-width: 650px) {
//     padding: 0 50px;
//     max-width: 100%;
//     box-sizing: border-box;
//   }
// `;
// const ButtonExtended = styled(Button)`
//   border-radius: 40px;
//   height: 65px;
//   white-space: nowrap;
//   margin: 7px;
//   padding: 20px 54px;
//   box-sizing: border-box;
//   @media (max-width: 650px) {
//     margin: 10px;
//   }
// `;
const TextExtend = styled(Text)`
  box-sizing: border-box;
  max-width: 616px;
  @media (max-width: 1200px) {
    padding: 20px 0;
    font-size: 90px !important;
    line-height: 90px;
  }
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px;
  }
`;
const WrapperSection = styled.div`
  min-height: 700px;
  max-width: 100%;
  margin-top: -90px;
  box-sizing: border-box;
  display: flex;
  background-image: url(${(props) => (props.banner ? props?.banner : banner)});
  background-size: cover;
  background-position: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 650px) {
    min-height: 520px;
  }
`;
const Content = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  @media (min-width: 1400px) {
    padding: 0 230px;
  }
  @media (max-width: 1300px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 100%;
    margin: 142px 20px 60px !important;
  }
`;

const Description = styled(Text)`
  width: 60%;
  margin-bottom: 30px;
  margin-top:10px ;
  @media (max-width: 1000px) and (min-width: 651px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 95%;
  }
`