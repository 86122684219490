import React from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import { redirectPage } from "../../hooks/redirect";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../components/RichtextHelper/CustomText";

export const DraperNFTs = ({ data }) => {
  if (!data) return null;
  return (
    <WrapperSection>
      <WrapperContent>
        <Title
          font={typography.type.code}
          tag="h3"
          lineHeight={90}
          weight={typography.weight.regular1}
          color={color.primary.white}
        >
            <RichText
                render={data?.title?.raw}
                htmlSerializer={htmlSerializer}
            />
        </Title>
        <WrapButton>
          <ButtonExpand
            onClick={() =>
              redirectPage({
                url: data?.button_link?.url,
                type: data?.button_link?.type,
                link_type: data?.button_link?.link_type,
              })
            }
            type="primary"
          >
            {data?.button_label?.text}
          </ButtonExpand>
        </WrapButton>
      </WrapperContent>
    </WrapperSection>
  );
};

const WrapButton = styled.div``;

const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  padding: 100px 132px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 124px;
  background-color: ${color.primary.green40};
  @media (max-width: 1300px) and (min-width: 1101px) {
    padding: 100px 80px;
    gap: 40px;
  }
  @media (max-width: 1100px) and (min-width: 651px) {
    padding: 60px 40px;
    flex-direction: column;
    gap: 40px;
  }
  @media (max-width: 650px) {
    padding: 30px 20px 70px 20px;
    max-width: 100vw;
    flex-direction: column;
    gap: 20px;
  }
`;
const Title = styled(Text)`
  font-family: ${typography.type.code};
  font-style: normal;
  font-weight: 500;
  font-size: ${typography.size.code}px;
  line-height: 90px;
  color: ${color.primary.white};
  @media (max-width: 1200px) {
    font-size: 76px !important;
    line-height: 76px;
  }
  @media (max-width: 1100px) {
    font-size: 80px !important;
    line-height: 80px;
  }
  @media (max-width: 992px) {
    font-size: 72px !important;
    line-height: 72px;
    width: auto;
  }
  @media (max-width: 650px) {
    font-size: ${typography.size.l4}px!important;
    line-height: 62px;
  }
`;
const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  align-items: center;
  @media (max-width: 650px) {
    flex-direction: column;
    gap: 40px;
    margin-top: 0;
  }
`;
const ButtonExpand = styled(Button)`
  border-radius: 40px;
  height: 65px;
  padding: 20px 45px;
  background-color: ${(props) => props.color && props.color};
  /* margin-top: auto; */
  @media (max-width: 650px) {
    margin: auto;
  }
  @media (max-width: 376px) {
    width: 100%;
  }
`;