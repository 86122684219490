import React from 'react'
import { ThemeProvider } from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Sidebar } from '../components/Sidebar';
import FooterContainer from '../containers/FooterContainer';
import { Header } from '../containers/HeaderContainer';
import { VentureContainer } from '../containers/VentureContainer';
import useApp, { withAppContext } from '../contexts/context'
import { useSiteMetadata } from '../hooks/useQuerydata';
import { useQueryVenture } from '../hooks/useQueryVenture';

const Venture = () => {
  const { toggle, handleToggleLayout } = useApp();
  const { allPrismicFooter, allPrismicHeader } = useSiteMetadata();
  const { allPrismicVentures } = useQueryVenture()
  const dataHeader = allPrismicHeader?.nodes[0]?.data;
  const theme = {
    bgColor: 'white',
  }
  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={
          <Header
            toggleLayout={handleToggleLayout}
            data={dataHeader}
          />
        }
        footer={
          <FooterContainer data={allPrismicFooter?.nodes[0]?.data} />
        }
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        seo={
          <SEO data={allPrismicVentures?.nodes[0]?.data?.body7} />
        }
      >
        <VentureContainer data={allPrismicVentures?.nodes[0]?.data} />
      </Layout>
    </ThemeProvider>
  )
}

export default withAppContext(Venture);
