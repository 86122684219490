import { useStaticQuery, graphql } from "gatsby"

export const useQueryVenture = () => {
  const { allPrismicVentures } = useStaticQuery(
    graphql`
      query QueryVenture {
        allPrismicVentures {
          nodes {
            data {
              banner {
                url
                alt
              }
              banner_title {
                html
                text
                raw
              }
              banner_description {
                html
                text
                raw
              }
              cta {
                button_label {
                  html
                  text
                  raw
                }
                button_link {
                  url
                  link_type
                  type
                }
              }
              body {
                ... on PrismicVenturesBodyConection {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    name {
                      text
                      html
                      raw
                    }
                    subname {
                      text
                      html
                      raw
                    }
                    color_bubble
                    quantity {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body1 {
                ... on PrismicVenturesBody1JoinUs {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                  }
                  items {
                    description {
                      html
                      text
                      raw
                    }
                    content {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              interest_title {
                html
                text
                raw
              }
              interest_button_label {
                text
              }
              interest_button_link {
                url
                link_type
                type
              }
              body2 {
                ... on PrismicVenturesBody2Interest {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    icon {
                      url
                      alt
                    }
                    interest_description {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body3 {
                ... on PrismicVenturesBody3HowItWork {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    icon {
                      url
                      alt
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body4 {
                ... on PrismicVenturesBody4MeetOurStartups {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    logo {
                      url
                      alt
                    }
                    raised {
                      html
                      text
                      raw
                    }
                    min_investment {
                      html
                      text
                      raw
                    }
                    raised_prefix {
                      text
                    }
                  }
                }
              }
              body5 {
                ... on PrismicVenturesBody5Reviews {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    name {
                      html
                      text
                      raw
                    }
                    position {
                      html
                      text
                      raw
                    }
                    comment {
                      html
                      text
                      raw
                    }
                    avatar {
                      url
                      alt
                    }
                  }
                }
              }
              body6 {
                ... on PrismicVenturesBody6Investor {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                    button_label {
                      html
                      text
                      raw
                    }
                    button_link {
                      url
                      link_type
                      type
                    }
                  }
                }
              }
              body7 {
                ... on PrismicVenturesBody7GeneralCard {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    robot {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                    googlebot {
                      html
                      text
                      raw
                    }
                    favicon {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                    bingbot {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicVenturesBody7OpenGraph {
                  primary {
                    og_url {
                      url
                      type
                      link_type
                    }
                    og_type {
                      html
                      text
                      raw
                    }
                    og_title {
                      html
                      text
                      raw
                    }
                    og_site_name {
                      html
                      text
                      raw
                    }
                    og_locale {
                      html
                      text
                      raw
                    }
                    og_image_width {
                      html
                      text
                      raw
                    }
                    og_image_height {
                      html
                      text
                      raw
                    }
                    og_image {
                      alt
                      url
                    }
                    og_description {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicVenturesBody7TwitterCard {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    image {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicVenturesBody7PinterestProductPin {
                  slice_type
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    price
                    currency
                    availability
                  }
                }
              }
              body8 {
                ... on PrismicVenturesBody8Portfolio {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    button_label {
                      html
                      text
                      raw
                    }
                    button_link {
                      url
                      link_type
                      type
                    }
                    description {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                  }
                }
              }
              body9 {
                ... on PrismicVenturesBody9DraperNfts {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    button_link {
                      url
                      link_type
                      type
                    }
                    button_label {
                      html
                      text
                      raw
                    }
                  }
                }
              }
            }
          }
        }
      }
      `
  )

  return {
    allPrismicVentures
  }
}