import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
// import Favourite from "../../images/venture-favourite.png";
// import Global from "../../images/venture-global.png";
// import Network from "../../images/venture-network.png";
// import Sector from "../../images/venture-sector.png";
// import Stage from "../../images/venture-stage.png";
import htmlSerializer from "../SpaceConntainer/RichtextHelper/CustomText";
import { redirectPage } from "../../hooks/redirect";

export const Interest = ({ title, button, data, cta }) => {
  if (!title || !button || !data || !cta) return null;
  return (
    <WrapperSection>
      <Title
        font={typography.type.code}
        tag="h3"
        lineHeight={90}
        weight={typography.weight.regular1}
        color={color.primary.blue}
        capital="uppercase"
      >
        <RichText render={title?.raw} htmlSerializer={htmlSerializer} />
      </Title>
      <WrapperContent>
        {data &&
          data?.slice(0,2)?.map((item, i) => (
            <WrapperItem key={i}>
              <Div>
                <WrapperTitle
                  color={
                    i === 0 ? color.primary.green40 : color.secondary.danube
                  }
                >
                  <Title
                    tag="h4"
                    font={typography.type.code}
                    lineHeight={56}
                    weight={typography.weight.regular1}
                    color={color.primary.white}
                    capital="uppercase"
                    align="center"
                  >
                    {item?.primary?.title?.text}
                  </Title>
                </WrapperTitle>
                <WrapperContentItem>
                  {item.items &&
                    item.items.map((item, index) => (
                      <Item key={index}>
                        <Icon src={item?.icon?.url}></Icon>
                        <ItemText
                          font={typography.type.primary}
                          lineHeight={28}
                          size={typography.size.s4}
                          weight={typography.weight.regular}
                          color={color.netraul.black100}
                        >
                          {item?.interest_description?.text}
                        </ItemText>
                      </Item>
                    ))}
                </WrapperContentItem>
              </Div>
            </WrapperItem>
          ))}
      </WrapperContent>
      <ButtonExpand
        onClick={() =>
          redirectPage({
            url: cta?.url,
            type: cta?.type,
            link_type: cta?.link_type,
          })
        }
      >
        {button?.text}
      </ButtonExpand>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  background-color: ${color.primary.white};
  max-width: 1500px;
  margin: auto;
  padding: 150px 131px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  @media (max-width: 1300px) {
    padding: 80px;
  }
  @media (max-width: 1200px) {
    padding: 70px 40px 100px 40px;
    gap: 40px;
  }
  @media (max-width: 650px) {
    padding: 70px 20px 80px 20px;
    gap: 40px;
    width: 100%;
    box-sizing: border-box;
  }
`;
const Title = styled(Text)`
  @media (max-width: 650px) {
    text-align: center;
    line-height: 62px;
    font-weight: 500;
  }
`;
const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 35px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 70px;
  }
`;
const WrapperItem = styled.div`
  width: 50%;
  padding: 0 52px 0 52px;
  background: linear-gradient(to bottom, #fff 250px, #f4f6f8 250px);
  @media (max-width: 992px) {
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    width: auto;
  }
  @media (max-width: 650px) {
    width: auto;
    padding: 0 20px;
    background: linear-gradient(to bottom, #fff 200px, #f4f6f8 200px);
  }
`;
const Div = styled.div`
  height: 100%;
`;

const WrapperContentItem = styled.div`
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  background-color: ${color.primary.blue05};
  gap: 36px;
  @media (max-width: 376px) {
    padding: 46px 0;
  }
`;
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 0 52px; */
  /* padding: 80px 0; */
  background-color: ${(props) => props.color && props.color};
  min-height: 250px;
  box-sizing: border-box;
  padding: 0 30px;
  @media (max-width: 650px) {
    min-height: 200px;
    span {
      font-weight: 500;
      font-size: 30px !important;
      line-height: 36px;
    }
  }
`;
const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
`;
const ItemText = styled(Text)``;
const Icon = styled.img`
  max-width: 24px;
`;
const ButtonExpand = styled(Button)`
  border-radius: 40px;
  width: fit-content;
  padding: 0 45px;
  height: 65px;
  align-self: center;
`