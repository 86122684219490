import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import {Bubbles} from "../../components/Bubble/Bubbles"

// const dataBubble = [
//     {
//         color: "#3DAE73",
//         quantity: "1",
//         name: "Draper Startup House Venture Fund",
//     },
//     {
//         color: "#FFCC00",
//         quantity: "60+",
//         subName: "From over",
//         name: "Countries",
//     },
//     {
//         color: "#204370",
//         quantity: "24+",
//         name: "Draper Funds",
//     },
//     {
//         color: "#4C8FCC",
//         quantity: "215+",
//         name: "Introductions made",
//     }
// ]
export const Conection = ({ data, items }) => {
  if (!data || !items) return null;
  return (
    <WrapperSection>
      <Image>
        <Bubbles data={items} type="ventures" />
      </Image>
      <WrapperContent>
        <Title
          tag="h3"
          font={typography.type.code}
          weight={typography.weight.regular1}
          lineHeight={90}
          capital="uppercase"
          color={color.primary.blue}
        >
          <RichText render={data?.title?.raw} htmlSerializer={htmlSerializer} />
        </Title>
        <ImageMobile>
          <Bubbles data={items} type="ventures" />
        </ImageMobile>
        <Content
          font={typography.type.primary}
          weight={typography.weight.regular}
          color={color.netraul.black100}
          lineHeight={26}
        >
          <RichText
            render={data?.description?.raw}
            htmlSerializer={htmlSerializer}
          />
        </Content>
        {/* <Description
          font={typography.type.primary}
          weight={typography.weight.regular}
          color={color.netraul.black100}
          size={20}
          lineHeight={30}
        >
          No matter your size or business stage, we will connect you with mentors and funding to accelerate your vision.
        </Description> */}
      </WrapperContent>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  box-sizing: border-box;
  padding: 100px 90px 140px 40px;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  gap: 0 125px;
  background-color: ${color.primary.white};
  @media (max-width: 1400px) {
    padding-right: 40px;
  }
  @media (max-width: 1100px) {
    gap: 0 40px;
    padding: 80px 20px;
  }
  @media (max-width: 650px) {
    gap: 0;
  }
`;
const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 32px;
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
const Title = styled(Text)`
  @media (min-width: 1400px) {
    margin-top: 70px;
  }
  @media (max-width: 1000px) and (min-width: 651px) {
    line-height: 62px;
  }
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px;
    font-weight: 500;
  }
`;
const Content = styled(Text)`
  width: 85%;
  span {
    font-size: inherit !important;
  }
  @media (max-width: 1200px) and (min-width: 1100px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;
// const Description = styled(Text)`
//   width: 79%;
//   @media (max-width: 650px) {
//     width: 100%;
//   }
// `
const Image = styled.div`
  padding-bottom: 50px;
  width: 50%;
  height: fit-content;
  @media (max-width: 800px) {
    display: none;
  }
`;
const ImageMobile = styled.div`
  padding-bottom: 50px;
  display: none;
  @media (max-width: 800px) {
    display: block;
    padding-bottom: 20px;
  }
`;
