import { RichText } from "prismic-reactjs";
import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { Text } from "../../components/Text/Text";
import left from "../../images/arrow-left.svg";
import right from "../../images/arrow-right.svg";
import { color, typography } from "../../shared/style";

export const SuccessStories = ({ data }) => {
  const [item, setItem] = useState(data?.items[0]);
  const [index, setIndex] = useState(0);
  const { items, primary } = data || {};
  const changeItem = (i) => {
    setIndex(i);
    setItem(data?.items[i]);
  };
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <WrapperTitle>
        <Title
          font={typography.type.code}
          tag="h3"
          lineHeight={90}
          weight={typography.weight.regular1}
          color={color.primary.blue}
          capital="uppercase"
        >
          <RichText
            render={primary?.title?.raw}
            htmlSerializer={htmlSerializer}
          />
        </Title>
        <WrapperSlide>
          <GroupButton>
            <ButtonSlide
              onClick={() =>
                changeItem(index > 0 ? index - 1 : items.length - 1)
              }
              icon={left}
            ></ButtonSlide>
            <ButtonSlide
              onClick={() =>
                changeItem(index < items.length - 1 ? index + 1 : 0)
              }
              icon={right}
            ></ButtonSlide>
          </GroupButton>
          <WrapperImage>
            <Image src={item?.logo?.url}></Image>
          </WrapperImage>
        </WrapperSlide>
      </WrapperTitle>
      <WrapperContent>
        <Description
          font={typography.type.code}
          size={typography.size.l3}
          lineHeight={56}
          weight={typography.weight.regular1}
          color={color.primary.white}
          capital="uppercase"
        >
          {item?.title?.text}
        </Description>
        <Content
          font={typography.type.primary}
          size={typography.size.s3}
          lineHeight={26}
          weight={typography.weight.regular}
          color={color.primary.white}
        >
          <RichText
            render={item?.description?.raw}
            htmlSerializer={htmlSerializer}
          />
        </Content>
        <Statistic>
          <Item>
            <Number
              font={typography.type.code}
              tag="h4"
              lineHeight={56}
              weight={typography.weight.regular1}
              color={color.primary.white}
              capital="uppercase"
            >
              {item?.raised_prefix?.text}
              {item?.raised?.text}
            </Number>
            <Label
              font={typography.type.primary}
              size={typography.size.s3}
              lineHeight={26}
              weight={typography.weight.bold}
              color={color.primary.white}
            >
              Raised
            </Label>
          </Item>
          <Item>
            <Number
              font={typography.type.code}
              tag="h4"
              lineHeight={56}
              weight={typography.weight.regular1}
              color={color.primary.white}
              capital="uppercase"
            >
              {item?.min_investment?.text}
            </Number>
            <Label
              font={typography.type.primary}
              size={typography.size.s3}
              lineHeight={26}
              weight={typography.weight.bold}
              color={color.primary.white}
            >
              Min. Investment
            </Label>
          </Item>
        </Statistic>
      </WrapperContent>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  max-width: 1500px;
  padding: 150px 131px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 32px;
  background-color: ${color.primary.white};
  @media (max-width: 1200px) {
    gap: 20;
    padding: 120px 40px;
  }
  @media (max-width: 992px) {
    flex-direction: column;
  }
  @media (max-width: 650px) {
    padding: 100px 20px;
    max-width: 100%;
    box-sizing: border-box;
    gap: 0;
  }
`;

const WrapperTitle = styled.div`
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 1001px) {
    width: 30%;
  }
`;
const WrapperContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${color.primary.green40};
  padding: 75px 90px 35px;
  @media (max-width: 650px) {
    padding: 40px 20px;
  }
`;
const Title = styled(Text)`
  @media (max-width: 1000px) {
    font-size: 62px !important;
    line-height: 62px !important;
  }
  @media (max-width: 376px) {
    width: 50%;
    margin-bottom: -66px;
  }
`;
const WrapperSlide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const GroupButton = styled.div`
  align-self: flex-end;
  display: flex;
  gap: 5px;
`;
const ButtonSlide = styled(Button)`
  border: none;
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  background-color: ${color.dark.dark05};
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  padding: 0;
  &:hover {
    border-color: ${color.primary.blue};
  }
  span {
    display: none;
  }
`;
const WrapperImage = styled.div`
  background-color: ${color.dark.dark05};
  padding: 35px 100px;
  @media (max-width: 1250px) and (min-width: 1001px) {
    padding: 35px 50px;
  }
  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 376px) {
    margin-top: 50px;
    padding: 20px 50px;
  }
`;
const Image = styled.img`
  max-width: initial;
  width: -webkit-fill-available;
  @media (max-width: 1000px) {
    width: initial;
  }
`;
const Description = styled(Text)`
  max-width: 455px;
  @media (max-width: 650px) {
    font-weight: 400;
    font-size: 36px !important;
    line-height: 36px;
    margin-bottom: 24px;
  }
`;
const Content = styled(Text)`
  margin-top: 32px;
  span {
    font-size: inherit;
  }
`;
const Statistic = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 58px;
  display: flex;
  gap: 90px;
  @media (max-width: 650px) {
    gap: 40px;
  }
`;
const Item = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Number = styled(Text)`
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px;
  }
`;
const Label = styled(Text)``;
