import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import { Text } from "../Text/Text";
import { Button } from "../Button/Button";
import htmlSerializer from "../RichtextHelper/CustomText";
import { color } from "../../shared/style";
import {redirectPage} from "../../hooks/redirect";

export const PortfolioItem = ({ item, noLink }) => {

  return (
    <WrapperItem>
      <Item>
        <Info>
          <Avatar src={item?.image.url} />
        </Info>
        <Comment
          font="Oxygen"
          lineHeight={26}
          color={color.netraul.black90}
        >
          <RichText
            render={item?.description?.raw}
            htmlSerializer={htmlSerializer}
          />
        </Comment>
      </Item>
      {!noLink ? (
        <WrapButton>
          <ButtonExpand
            onClick={() =>
              redirectPage({
                url: item?.button_link?.url,
                type: item?.button_link?.type,
                link_type: item?.button_link?.link_type,
              })
            }
            type="primary"
          >
            {item?.button_label?.text}
          </ButtonExpand>
        </WrapButton>
      ) : null}
    </WrapperItem>
  );
};

const WrapperItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: auto !important;
  display: block;
  margin: 0 17px;
  padding-bottom: 40px;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #fff 43px, ${color.primary.blue05} 0px);

  @media (max-width: 650px) {
    margin: 0;
  }
`;
const Item = styled.div`
  padding: 0 30px;
  box-sizing: border-box;
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;

const Icon = styled.img`
  max-width: 22px;
  max-height: 22px;
  width: 100%;
  height: 100%;
  padding: 33px 0 30px 0;
`;
const Info = styled.div`
  display: flex;
  margin-bottom: 40px;
  height: 130px;
  background: #fff;
  border: 1px solid #f5f2f2;
`;
const Avatar = styled.img`
  max-height: 130px;
  height: 100%;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
`;
const Detail = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1190px) and (min-width: 1100px) {
    padding-left: 20px;
  }
  @media (max-width: 1100px) and (min-width: 1000px) {
    padding-left: 10px;
  }
`;
const ButtonExpand = styled(Button)`
  border-radius: 40px;
  height: 65px;
  padding: 20px 45px;
  background-color: ${(props) => props.color && props.color};
  /* margin-top: auto; */
  @media (max-width: 650px) {
    margin: auto;
  }
  @media (max-width: 376px) {
    width: 100%;
  }
`;
const Comment = styled(Text)``;

const WrapButton = styled.div`
  padding: 0 30px;
  margin-top: 20px;
  display: flex;
    justify-content: center;
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;