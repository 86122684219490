import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../components/RichtextHelper/CustomText";

export const HowItWork = ({ data }) => {
  const { items, primary } = data || {};
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <WrapperItem>
        <Title
          tag="h3"
          font={typography.type.code}
          weight={typography.weight.regular1}
          lineHeight={90}
          color={color.primary.blue}
          capital="uppercase"
        >
          <RichText render={primary?.title?.raw} htmlSerializer={htmlSerializer} />
        </Title>
      </WrapperItem>
      {items && items.map((item, i) => {
        return (
          <WrapperItem key={i}>
            <WrapperContentItem>
              <WrapImage>
                <Icon src={item?.icon?.url}></Icon>
              </WrapImage>
              <Description
                font={typography.type.primary}
                weight={typography.weight.bold}
                lineHeight={28}
                color={color.netraul.black100}
                size={typography.size.m1}
              >
                {item?.description?.text}
              </Description>
            </WrapperContentItem>
            <Step
              font={typography.type.code}
              weight={typography.weight.regular1}
              lineHeight={56}
              size={typography.size.l3}
              color={color.dark.dark10}
              capital="uppercase"
            >
              {`0${i + 1}`}
            </Step>
          </WrapperItem>
        );
      })}
    </WrapperSection>
  );
};

const WrapImage = styled.div`
  max-width: 40px;
  max-height: 40px;
  margin-bottom: 32px;
  @media (max-width: 1200px) {
    margin-bottom: 0;
    margin-right: 24px;
  }
`;
const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  padding: 110px 131px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 64px;
  @media (max-width: 1490px) {
    gap: 20px;
  }
  @media (max-width: 1400px) {
  }
  @media (max-width: 1300px) {
    padding: 80px;
  }
  @media (max-width: 1200px) and (min-width: 851px) {
    gap: 20px;
    padding: 100px 40px;
    flex-direction: column;
  }
  @media (max-width: 992px) {
    padding: 70px 40px;
    flex-direction: column;

    gap: 20px;
    width: 100%;
    box-sizing: border-box;
  }
  @media (max-width: 650px) {
    padding: 80px 20px;
  }
`;
const WrapperItem = styled.div`
  display: flex;
  position: relative;
  background-color: ${color.primary.white};
  padding: 45px 36px 45px 44px;
  width: 25%;
  &:first-child {
    background-color: ${color.primary.blue05};
    padding: 0;
  }
  @media (max-width: 1200px) {
    width: auto;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px;
    &:first-child {
      padding: 0;
      padding-bottom: 20px;
    }
  }
  @media (max-width: 650px) {
    gap: 0;
  }
`;

const WrapperContentItem = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    flex-direction: row;
    align-items: flex-start;
    width: 75%;
  }
`;

const Title = styled(Text)`
  @media (max-width: 1200px) {
    font-size: 62px !important;
    line-height: 62px;
  }
  @media (max-width: 650px) {
    line-height: 62px;
    letter-spacing: -0.015em;
  }
`;
const Icon = styled.img`
  width: 40px;
  max-width: 40px;
  max-height: 40px;
  color: ${color.secondary.danube};
`;
const Step = styled(Text)`
  opacity: 0.1;
  position: absolute;
  top: 20px;
  right: 20px;
  min-width: 60px;
  text-align: right;
  @media (max-width: 850px) {
    position: initial;
  }
`;
const Description = styled(Text)`
  min-width: 142px;
`;