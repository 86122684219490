import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import htmlSerializer from "../../components/RichtextHelper/CustomText";

export const JoinUs = ({ data }) => {
  const { items, primary } = data || {};
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <WrapperContent>
        <Title
          font={typography.type.code}
          tag="h3"
          lineHeight={90}
          weight={typography.weight.regular1}
          color={color.primary.blue}
          capital="uppercase"
        >
          <RichText
            render={primary?.title?.raw}
            htmlSerializer={htmlSerializer}
          />
        </Title>
        <ImageMobile src={primary?.image?.url}></ImageMobile>
        <WrapperItem>
          {items &&
            items.map((item, i) => {
              return (
                <Item key={i}>
                  <Description
                    font={typography.type.primary}
                    lineHeight={26}
                    weight={typography.weight.bold}
                    color={color.netraul.black100}
                  >
                    {item?.description?.text}
                  </Description>
                  <Content
                    font={typography.type.primary}
                    lineHeight={26}
                    weight={typography.weight.regular}
                    color={color.netraul.black100}
                  >
                    {item?.content?.text}
                  </Content>
                </Item>
              );
            })}
        </WrapperItem>
      </WrapperContent>
      <Image src={primary?.image?.url}></Image>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  gap: 90px;
  box-sizing: border-box;

  @media (max-width: 1000px) and (min-width: 651px) {
    gap: 40px;
  }
  @media (max-width: 650px) {
  }
`;

const WrapperContent = styled.div`
  padding: 116px 0 50px 131px;
  @media (max-width: 1300px) {
    padding-left: 80px;
  }
  @media (max-width: 1200px) {
    padding-left: 40px;
  }
  @media (max-width: 1000px) {
    padding: 100px 0 50px 40px;
  }
  @media (max-width: 800px) {
    padding: 70px 0px;
  }
`;
const Image = styled.img`
  object-fit: cover;
  width: 35%;
  @media (max-width: 800px) {
    display: none;
  }
`;
const Title = styled(Text)`
  @media (max-width: 992px) {
    line-height: 62px;
    padding-left: 20px;
    display: block;
    margin-bottom: 40px;
  }
`;
const WrapperItem = styled.div`
  margin-top: 45px;
  @media (max-width: 800px) {
    padding: 0 20px;
    margin-top: 40px;
  }
`;
const Item = styled.div`
  display: flex;
  padding: 50px 0;
  border-top: 1px solid rgba(35, 35, 39, 0.1);
  justify-content: space-between;
  @media (max-width: 1000px) and (min-width: 651px) {
    padding: 20px 0;
  }
  @media (max-width: 650px) {
    flex-direction: column;
    gap: 20px;
    padding: 30px 0;
    border-top: none;
    padding-top: 0;
  }
`;
const Description = styled(Text)`
  width: 25%;
  @media (max-width: 1000px) and (min-width: 800px) {
    width: 30%;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;
const Content = styled(Text)`
  width: 60%;
  @media (max-width: 650px) {
    width: 100%;
    color: rgba(35, 35, 39, 0.8);
  }
`;
const ImageMobile = styled.img`
  display: none;
  @media (max-width: 800px) {
    display: block;
    height: 292px;
    width: 100vw;
    object-fit: cover;
    padding: 0 20px;
    box-sizing: border-box;
  }
`;