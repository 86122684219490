import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { PortfolioList } from "../../components/Portfolio/PortfolioList";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";

export const Portfolio = ({ data }) => {
  const { items, primary } = data;
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <WrapperTitle>
        <Title
          tag="h3"
          weight={typography.weight.regular1}
          lineHeight={90}
          capital="uppercase"
          color={color.primary.blue}
        >
          <RichText
            render={primary?.title?.raw}
            htmlSerializer={htmlSerializer}
          />
        </Title>
      </WrapperTitle>
      <PortfolioList items={items} noLink={false} />
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  padding: 130px 131px;
  margin: auto;
  background-color: ${color.primary.white};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    padding: 100px 40px;
  }
  @media (max-width: 992px) {
    padding: 100px 30px;
  }
  @media (max-width: 650px) {
    padding: 80px 20px;
  }
`;

const WrapperTitle = styled.div`
  max-width: 80%;
  @media (max-width: 1200px) {
    max-width: 100%;

    width: 100%;
  }
  @media (max-width: 992px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: auto;
  }
`;
const Title = styled(Text)`
  width: fit-content;
  margin: 0 auto;
  @media (max-width: 1000px) and (min-width: 651px) {
    line-height: 62px;
  }
  @media (max-width: 650px) {
    line-height: 62px;
  }
`;

Portfolio.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        rating: PropTypes.string,
        image: PropTypes.shape({ url: PropTypes.string }),
        name: PropTypes.shape({
          raw: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
              text: PropTypes.string,
            })
          ),
        }),
        position: PropTypes.shape({
          raw: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
              text: PropTypes.string,
            })
          ),
        }),
        comment: PropTypes.shape({
          raw: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
              text: PropTypes.string,
            })
          ),
        }),
      })
    ),
    primary: PropTypes.shape({
      title: PropTypes.shape({
        raw: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};
