import React from "react";
import styled from "styled-components";
import { color } from "../../shared/style";
import { Banner } from "./Banner";
import { BuildTogether } from "./BuildTogether";
import { Conection } from "./Conection";
import { HowItWork } from "./HowItWork";
import { Interest } from "./Interest";
import { JoinUs } from "./JoinUs";
import { Portfolio } from "./Portfolio";
import { SuccessStories } from "./SucessStories";
import { Testimoials } from "./Testimoials";
import { DraperNFTs } from "./DraperNFT";

export const VentureContainer = ({ data }) => {
  return (
    <WrapperLayout>
      <Banner
        banner={data?.banner}
        title={data?.banner_title?.text}
        description={data?.banner_description?.text}
        group_button={data?.cta}
      />
      <Portfolio data={data?.body8[0] === undefined ? {} : data?.body8[0]} />
      <DraperNFTs data={data?.body9[0] === undefined ? null : data?.body9[0]?.primary}/>
      <Conection
        data={data?.body[0] === undefined ? null : data?.body[0]?.primary}
        items={data?.body[0] === undefined ? null : data?.body[0]?.items}
      />
      <JoinUs data={data?.body1[0] === undefined ? {} : data?.body1[0]} />
      <Interest
        title={data?.interest_title}
        button={data?.interest_button_label}
        data={data?.body2}
        cta={data?.interest_button_link}
      />
      <HowItWork data={data?.body3[0] === undefined ? {} : data?.body3[0]} />
      {data?.body4[0] && (
        <SuccessStories
        data={data?.body4[0] === undefined ? {} : data?.body4[0]}
      />
      )}
      <Testimoials data={data?.body5[0] === undefined ? {} : data?.body5[0]} />
      <BuildTogether
        data={data?.body6[0] === undefined ? null : data?.body6[0]?.primary}
      />
    </WrapperLayout>
  );
};

const WrapperLayout = styled.div`
  margin-bottom: 100px;
  margin: 0 auto;
  margin-top: 90px;
  background-color: ${color.primary.blue05};
  position: relative;
`;
